import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges } from '@angular/core';
import L, {
  circleMarker,
  control,
  divIcon,
  icon,
  LatLng,
  latLng,
  Layer,
  layerGroup,
  Map,
  marker,
  polygon,
  polyline,
  tileLayer
} from 'leaflet';
import {MapTiles} from '../../settings/user-profile/user-profile-type';
import {MapsService} from '../../../services/maps.service';
import {cloneDeep, findIndex, forEach, groupBy, keys, map, orderBy} from 'lodash';
import {DateFormatterService} from '../../../services/dateformat';
import {EChartDataType, EChartSeries, EChartSeriesUpdate} from '../e-charts/e-charts-data-type';
import moment from 'moment';

import {LangUtilService} from '../../util/lang-util.service';
import {OrderStatusColorValuesService} from '../../../services/order-status-color-values.service';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import {TrackingService} from '../../../services/tracking.service';
import {MessageService} from 'primeng/api';
import {IotzenPillType} from '../iotzen-pills/iotzen-pill-type';
import {CONSTANT} from '../../../config/constant';
import {GeoFenceObject, TrackerIcons} from '../../../state/Fleet/models/fleet.models';
import {CommonService} from '../../../services/common.service';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {visualMaps} from '../../../state/dashboard/dashboard/dashboard.helper.service';
import {Actions, Subject} from '../../../auth/rules';
import {
  getPopupButtonsAndTip,
  getPopUpHeader,
  showPopupData
} from '../../../app/delivery-managenment-system/services/common.service';
import {Ability} from '@casl/ability';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getUserMapSelector} from '../../../state/User/user.selector';

const MMD_MODULE = CONSTANT.DMS_MODULES.MMD.MODULE;
const LMD_MODULE = CONSTANT.DMS_MODULES.LMD.MODULE;
declare let $: any;

@Component({
  selector: 'app-prime-map-journey-modal',
  templateUrl: './prime-map-journey-modal.html',
  styleUrls: ['./prime-map-journey-modal.scss'],
  providers: [MessageService,ToggleButtonModule,ConfirmPopupModule],
})
export class PrimeMapJourneyModal implements OnInit, OnChanges,OnDestroy {

  @Input() displayModal = true;
  @Output() sendModalClosed: EventEmitter<any> = new EventEmitter();
  @Input() modalBodyData: any = {};
  @Input() routesDetails: any = [];
  @Input() eventsToPlot: any = [];
  eventsToPlotCopy: any = [];
  @Input() mapMarkers: any = [];
  deliveryTempModal = false;
  @Input() header
  @Input() liveDataFromSocket: any = {};
  @Input() rowData: any;
  @Input() agentDetails: any;
  @Input() isRightSidebarClosed: boolean;
  @Input() reloadMap: number;
  liveDataFromSocketResultArray = [];
  @Input() dataViewType: any;
  @Input() displayPills = true;
  @Input() pageName = '';
  @Input() selectedOrderDeliveryGroup: any;
  @Input() carGpsHistoricalData: any
  @Input() geoFences: GeoFenceObject[];
  @Input() trackIcons: TrackerIcons;

  @Output() activeRouteChanged: EventEmitter<any> = new EventEmitter();
  @Output() routeLegendChanged: EventEmitter<any> = new EventEmitter();
  @Output() sendChartExpansionData: EventEmitter<any> = new EventEmitter();

  @Output() sendLatLngValues: EventEmitter<ILocationPayload> = new EventEmitter();
  @Output() emittGeoFenceRadiusPayload: EventEmitter<ILocationPayload> = new EventEmitter();

  ACTIONS=Actions;
  SUBJECT=Subject;

  chartModalOpen = false;
  moduleName: string = null;

  public eChartGraphData: EChartDataType = {
    series: [],
    legend: [],
    type: 'line',
    title: 'Stats',
    xAxis: [],
    visualMap: visualMaps,
    isDataZoomRequired: true
  };


  public map: any;
  markers: Layer[] = [];
  private url: string;
  private option: any;
  options = {
    layers: [],
    zoom: 5,
    zoomControl: false,
    center: latLng(null, null),
    key: 'marker1'
  };
  mapToView: any;
  layer: Layer;
  public mapDetails: MapTiles;
  public selectedMap: any;
  public selectedZoom: number;
  public selectedLatitude: number;
  public selectedLongitude: number;
  public center: any;
  public defaultMapDetails: any;
  public defaultPanelSize: Array<number> = [50, 50];
  route = polyline([]);
  routes = [];
  legendList: RouteLegend[] = [
    {
      'label': 'Actual Path',
      'color': '#00A3E3',
      'isActive': true,
      'type': 'actual'
    },
    {
      'label': 'Expected Path',
      'color': '#E34000',
      'isActive': false,
      'type': 'expected'
    }
  ];
  legendListCopy: RouteLegend[] = [];
  startMarkerLocation;
  // Marker for the parking lot at the base of Mt. Ranier trails

  startingLocation = marker([0, 0], {
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: 'assets/leaflet-icons/start-marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  });

  endingLocation = marker([0, 0], {
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: '../assets/leaflet-icons/end-marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  });

  agentMarkerLocation = marker([0, 0], {
    icon: icon({
      iconSize: [40, 50],
      iconAnchor: [13, 41],
      iconUrl: '../assets/leaflet-icons/marker-icon-active.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    })
  });

  _liveInfoTab = {
    isFirstTimeAgentDataReceived: true,
    tabLabel: 'Live',
    isGraphDisplayReq: false
  };

  agentRoute = polyline([]);
  agentRouteArray = [];
  newDataForAgentPolylineCount: number;

  eventMarkersArray = [];
  actionMessage: string;

  allDeliveryMarkers = [];

  allDeliveryLocMarkers = {};

  userMapOptionsSub$: Subscription;

  routeArray = [];
  test = 'test var';

  public boxStats = {
    show: false,
    showLoader: true,
  };

  enableLocationUpdate: boolean;
  enableRoutePoints = signal<boolean>(false);
  actualPathMarkers = [];
  showLoader = false;
  arrayOfPills: Array<IotzenPillType> = [];
  geomarkers: Layer[] = [];
  showGeofences = false;
  showEvents = false;
  eventsMarkers = {}
  geoFencesById = {}
  dataForGraph;
  constructor(public mapsService: MapsService, public dateFormatter: DateFormatterService, private lang: LangUtilService,
              private orderStatusColorService: OrderStatusColorValuesService,
              public trackingService: TrackingService,
              private messageService: MessageService,
              private commonService: CommonService,private ability: Ability,private store:Store) {
              this.legendListCopy = cloneDeep(this.legendList)
  }


  ngOnInit(): void {
    this.buildPillsArray();
    this.provideLocationUpdateAccess()
    this.userMapOptionsSub$ =
    this.store.pipe(select(getUserMapSelector)).subscribe(userMapOptions=>{
      this.mapDetails = userMapOptions
    })
  }

  eventCardClick(event){
    this.focusedEvent=event
    if (!this.showEvents) {
      this.plotEvents(this.eventsToPlotCopy);
      this.showEvents=true
    }
    const [lat,lng]=event?.coordinates || []
    this.map.panTo({lat,lng})
    setTimeout(() => {
      this.map.setZoomAround({lat,lng}, 24);
      this.eventsMarkers[event?._id]?.openPopup()
    }, 500);
  }

  geofenceCardClick(geofence:GeoFenceObject){
    this.focusedGeoFence=geofence
    this.showGeofences=true
    this.markers=[...(this.geomarkers || [])];
    const [lng,lat]=geofence?.geoLocation?.coordinates || []
    this.map.panTo({lat,lng})
    setTimeout(() => {
      this.map.setZoomAround({lat,lng}, 18);
    }, 500);
  }

  focusedGeoFence:GeoFenceObject
  focusedEvent
  searchGeoFence:string
  searchEvent:string


  showGeofencesList:boolean
  showEventsList:boolean
  showListSearchBar:boolean

  showEventsListData():void{
    if(!this.showEventsList){
      this.showEventsList=true
      this.showGeofencesList=false
      this.focusedEvent=null
    }else{
      this.showEventsList=false
    }
  }

  showGeofencesListData():void{
    if(!this.showGeofencesList){
      this.showGeofencesList=true;
      this.showEventsList=false;
      this.focusedGeoFence=null;
    }else{
      this.showGeofencesList=false
    }
  }



  ngOnChanges(changes: SimpleChanges) {
    try {
      for (const propName of Object.keys(changes)) {
        const change = changes[propName];
        // if (propName === 'modalBodyData') {
        //   if (change.currentValue !== change.previousValue) {
        //     this.defaultPanelSize = [50,50];
        //     this.liveDataFromSocketResultArray = [];
        //     this.removeLayer();
        //     this.getMapForSelectedUser();
        //     this.plotPolyLine();
        //     this.plotIcons();
        //     // this.plotSocketDataGraph({sensorData: null, generatedTimeStamp: null});
        //     this.buildGraph();

        //   }
        // }
        if (propName === 'reloadMap') {
          if (change.currentValue !== change.previousValue) {
            // this.buildGraph();
            this.messageService.clear();
            this.onPanelResize();
            setTimeout(() => {
              this.messageService.clear();
              this.panToActualPath();
            }, 600);
          }
        }
        if (propName === 'routesDetails') {
          if (change.currentValue !== change.previousValue) {
            this.defaultPanelSize = [70, 30];
            this.liveDataFromSocketResultArray = [];
            this.removeLayer();
            this.getMapForSelectedUser();
            this.plotPolyLine();
            this.plotIcons(this.enableLocationUpdate);
            //   this.plotSocketDataGraph({sensorData: null, generatedTimeStamp: null});
            //  this.buildGraph();
            //  this.onPanelResize();
            //   this.buildPillsArray(this.routesDetails)
          }
        }
        if (propName === 'liveDataFromSocket') {
          if (change.currentValue !== change.previousValue) {
            if (this.liveDataFromSocket) {
              this.setRiderMarkerLocation(this.liveDataFromSocket);
            }
            if (this.liveDataFromSocket && this.liveDataFromSocket.sensorData) {
              this.plotSocketDataGraph(this.liveDataFromSocket);
            }
          }
        }
        if (propName === 'agentDetails') {
          if (change.currentValue !== change.previousValue) {
            this.legendList = cloneDeep(this.legendListCopy);
            this.resetLegendActiveVaues();
            this.resetAgentRouteVariables();
            if (this.agentDetails && this.pageName === 'progress' || this.pageName === 'mmd-consignment-group') {
              setTimeout(() => {
                  this.setRiderLastLocation(this.agentDetails);
              }, 1000);
            }
          }
        }
        if (propName === 'rowData') {
          if (change.currentValue !== change.previousValue) {
            if (this.rowData) {
              this.legendList = cloneDeep(this.legendListCopy)
              this.moduleName = this.rowData?.moduleType;
              this.defaultPanelSize = [50, 50];
              this.liveDataFromSocketResultArray = [];
              this.plotEmptyGraph();
              this.buildGraph();
              this.onPanelResize();
              // this.resetFilterMenuOptions();
              this.resetLegendActiveVaues();
            }
          }
        }
        if (propName === 'carGpsHistoricalData') {
          if (change.currentValue !== change.previousValue) {
            if (this.carGpsHistoricalData?.length) {
              this.buildGraph();
            }
          }
        }
        if (propName === 'isRightSidebarClosed') {
          if (change.currentValue !== change.previousValue) {
            if (!this.isRightSidebarClosed) {
              this.removeLayer();
              this.resetAgentRouteVariables();
              this.removeGeoFences();
              this.showGeofences = false;
            } else {
              this.enableLocationUpdate = false;
              this.showEvents = false;
              this.showGeofences = false;
              this.showEventsList=false
              this.showGeofencesList = false;
              this.removeActualPathMarkers();
              this.enableRoutePoints.set(false);
              this.removeGeoFences();
            }
          }
        }

        if (propName === 'geoFences') {

          if (change.currentValue && change.currentValue !== change.previousValue) {
            this.plotGeoFence(this.geoFences);
            setTimeout(() => {
             // this.removeGeoFences();
            }, 0);
          }
        }

        if (propName === 'eventsToPlot') {
          if (change.currentValue && change.currentValue !== change.previousValue) {
            if (this.eventsToPlot && this.eventsToPlot.length > 0) {
              this.eventsToPlotCopy = [...this.eventsToPlot];
              this.showEvents && this.plotEvents(this.eventsToPlot);
              setTimeout(() => {
                this.showEvents && this.removeEvents();
              }, 0);
            } else {
              this.eventsToPlotCopy = [];
              this.removeplottedEvents();
                this.showEvents && this.removeEvents();
            }
          }
        }

      }
    } catch (e) {
      console.error(e);
    }
  }

  /*   resetFilterMenuOptions() {
      forEach(this.filterMenuOptions, r =>{
        r['active'] = false;
      })
      this.filterMenuOptions[0]['active'] = true;
    } */


  plotGeoFence(data: GeoFenceObject[]) {
    if (this.map && data && data.length > 0) {
      this.geomarkers=[]
      data.map(geofence => {
        const coordinates = map(geofence.location.coordinates, function (coordinate) {
          return latLng(coordinate[0], coordinate[1]);
        });
        const geoFencePlot = polygon(coordinates, {color: 'blue'});
        this.setTooltipContentGeoFence(geoFencePlot, geofence,coordinates[0]);
        this.geomarkers.push(geoFencePlot);
        geofence.assetId && geofence.radius &&
        this.accessLocationUpdate &&
        geoFencePlot.on('click',  () =>{
          this.geoFenceRadius=geofence?.radius || 0
          this.updateGeoFenceRadiusData={
            assetId:geofence?.assetId,
            geoFenceRadius: this.geoFenceRadius,
            lat: geofence?.geoLocation?.coordinates?.[1] || 0,
            lng: geofence?.geoLocation?.coordinates?.[0] || 0,
            label:geofence?.label || ''
          }
          this.geoFenceFormDialog=true
        });
      });
    }

    if(this.showGeofences) {
      this.markers=[...(this.geomarkers || [])];
    }
  }

  public geoFenceFormDialog : boolean;
  public geoFenceRadius : number
  public accessLocationUpdate : boolean
  public updateGeoFenceRadiusData:ILocationPayload

  closePopups(){
    this.map.closePopup()
  }

  updateGeoFenceRadius(){
    this.geoFenceFormDialog=false
    this.updateGeoFenceRadiusData={
      ...this.updateGeoFenceRadiusData,
      geoFenceRadius: this.geoFenceRadius,
    }
    this.emittGeoFenceRadiusPayload.emit(this.updateGeoFenceRadiusData)
  }

  provideLocationUpdateAccess() {
    if ((this.ability.can(Actions.UPDATE_LOCATION, Subject.MMD_MANAGEMENT))) {
      this.accessLocationUpdate = true;
    } else {
      this.accessLocationUpdate = false;
    }
  }


   arePopupsOpen() {
    const layers =this.map._layers;
    for (let layerId in layers) {
      const layer = layers[layerId];
      if (layer instanceof L.Marker && layer.getPopup() && layer.getPopup().isOpen()) {
        return true;
      }
    }
    return false;
  }

  setTooltipContentGeoFence(geoFencePlot: L.Polygon, data: GeoFenceObject,popupLocation) {
    geoFencePlot.bindPopup(
      getPopUpHeader('Geo-Fence: ', (data?.label || ''), `Geo-Fence Radius: ${data?.radius || 0}`) +
      showPopupData([]) +
      (this.enableLocationUpdate ? getPopupButtonsAndTip('Click on Geo-Fence to Edit Radius',false) : '')
      );

    geoFencePlot?.on('mouseover',()=>{
      !this.arePopupsOpen() &&
      geoFencePlot.openPopup(popupLocation)
    })
    geoFencePlot?.on('mouseout',()=>{
      geoFencePlot.closePopup()
    })
  }

  removeGeoFences() {
    this.showGeofences = !this.showGeofences;
    this.showGeofencesList=false
    this.showEventsList=false
    if (this.showGeofences) {
      this.markers=[...(this.geomarkers || [])];
    } else {
      this.markers = [];
    }
  }

  removeEvents() {
    this.showEvents = !this.showEvents;
    if (this.showEvents) {
      this.plotEvents(this.eventsToPlotCopy);
    } else {
      this.removeplottedEvents();
    }
  }

  onModalClose() {
    this.displayModal = false;
    this.showGeofences = false;
    this.showEvents = false;
    this.sendModalClosed.emit(true);
    this.removeLayer();
    this.resetAgentRouteVariables();
  }

  /* setPillIndexActive() {
      const _this = this;
      let index: number;
      if (this?.arrayOfPills?.length > 0) {
        index = findIndex(_this.arrayOfPills, function(type: any) { return type.id === _this.dataViewType; });
        if (index > -1) {
          return index;
        }
      }
    } */

  setActiveRoute(type) {
    /* forEach(this.legendList, function (leg) {
      if(type.id === 'both') {
        leg['isActive'] = true;
      } else {
        leg['isActive'] = false;
      }
      if(leg.type === type.id) {
        leg['isActive'] = true;
      }
    }); */
    this.dataViewType = type;
    //this.activeRouteChanged.emit(type)
  }

  buildPillsArray() {
    if (this.displayPills) {
      this.arrayOfPills = [
        {
          id: 'expected',
          label: 'Expected Path',
          description: 'Show Expected Path',
          inActiveImgSrc: '',
          activeImgSrc: '',
        },
        {
          id: 'actual',
          label: 'Actual Path',
          description: 'Show Actual Path',
          inActiveImgSrc: '',
          activeImgSrc: '',
        },
        {
          id: 'both',
          label: 'Both Path',
          description: 'Show Both Path',
          inActiveImgSrc: '',
          activeImgSrc: '',
        }
      ];
    } else {
      this.arrayOfPills = [];
    }


  }

  // ---------------leaflet map----------------

  onMapReady(map: Map) {
    $('div.leaflet-control-attribution').hide();
    // $('.leaflet-control').hide();
    this.map = map
    const attribution = map.attributionControl;
    attribution.setPrefix('');
    control.zoom({
      position: 'bottomright'
    }).addTo(map);
    this.plotGeoFence(this.geoFences)
  }

  toggleRoute(id: any) {

    forEach(this.routes, r => {
      if (r.id === id) {
        const rStatus = this.map.hasLayer(r.route);
        if (rStatus) {
          this.map.removeLayer(r.route);
        } else {
          this.map.addLayer(r.route);
        }

      }
    });
  }

  getMapForSelectedUser() {

        if (this.mapDetails) {
          if (this.mapDetails.zoom) {
            this.selectedZoom = this.mapDetails.zoom;
          } else {
            this.selectedZoom = 11;
          }
          if (this.mapDetails.centerLocation) {
            this.selectedLatitude = this.mapDetails.centerLocation.coordinates[0];
            this.selectedLongitude = this.mapDetails.centerLocation.coordinates[1];
          } else {
            this.selectedLatitude = 25.214017;
            this.selectedLongitude = 55.310220;
          }
          this.setMap(this.mapDetails);
          this.center = latLng(this.selectedLatitude, this.selectedLongitude);

          this.defaultMapDetails = {
            center: this.center,
            selectedZoom: this.selectedZoom,
          };

        }

  }

  setMap(mapSelected) {
    if (mapSelected && mapSelected.tile) {
      this.url = mapSelected.tile.url;
      this.option = mapSelected.tile.option;
      this.option = {...this.option,attributionControl:false};
      this.mapToView = tileLayer(this.url, this.option);
      if (mapSelected.tile['google_style']) {
        (L as any).gridLayer.googleMutant({
          type: 'roadmap',
          styles: mapSelected.tile['google_style'],
          disableDefaultUI: true,
        }).addTo(this.map);

      } else {
        if (this.map) {
          this.mapToView?.addTo(this.map);
        }
      }
      this.map.invalidateSize(true);
    }
  }

  // plotting markers
  plotIcons(locationUpdateStatus) {
    this.allDeliveryMarkers?.forEach((m) => {
      m.remove();
    });
    if (this.mapMarkers && this.mapMarkers.length > 0) {
      forEach(this.mapMarkers, (m) => {
        this.plotDelLoactionMarkers(m, locationUpdateStatus);
      });
    }
  }

  toggleLocationUpdatePermission(status:boolean) {
    this.enableLocationUpdate = status;
    this.plotIcons(this.enableLocationUpdate);
    this.plotGeoFence(this.geoFences);
    if(!status)this.geoFenceFormDialog = status;
    if(status && this.geoFenceFormDialog){
      this.geoFenceFormDialog=false
    }
  }

  plotDelLoactionMarkers(markerVal, draggable:boolean) {
    const {lat,lng,name:orderLocationId,shortHand} = markerVal
    const mOptions = markerVal?.mOptions || {
          color: "#373fa3",
          fillColor: "#fff",
          fillOpacity: 0.8,
          radius: 15,
          weight: 3,
        };

    if (lat && lng) {
      let startMarkerLocation = circleMarker([lat, lng], mOptions);
      const startLocation = { lat, lng };
      const icon = divIcon({
        html: `<div>
         <span class='leaflet-circle-marker-txt' style='
        color: ${mOptions.color};
        border:2px solid ${mOptions.color};
        width:${2 * mOptions.radius}px;
        height:${2 * mOptions.radius}px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        '>${shortHand} </span></div>`,
        className: "",
        iconSize: [40, 40],
      });
      let orderMarker  = marker(startLocation, {icon,  draggable});

      let popUpContent = markerVal.details + (draggable ? getPopupButtonsAndTip('Drag to Update Location',false) : '');

      orderMarker.bindPopup(popUpContent, {maxWidth : 1000,offset: [0, -8]}).openPopup();

      orderMarker.on("dragstart", () => {
        startMarkerLocation.remove();
        orderMarker.closePopup()
      });

      orderMarker.on("mouseover", () => {
        orderMarker.openPopup()
        this.addEventListenerForPopup()
      });

      orderMarker.on("dragend", (e) => {

        const {lat: latitude, lng: longitude} = e?.target?.getLatLng();
        popUpContent = markerVal.details + (draggable ? getPopupButtonsAndTip('Drag to Update Location') : '');
        orderMarker.bindPopup(popUpContent).openPopup();
        this.addEventListenerForPopup()


        startLocation.lat = latitude;
        startLocation.lng = longitude;

        startMarkerLocation = circleMarker( [latitude , longitude ] , mOptions);
        this.startMarkerLocation = layerGroup([ startMarkerLocation,  orderMarker ]);
        this.startMarkerLocation.addTo(this.map);

        this.allDeliveryMarkers.push(this.startMarkerLocation);

        this.foucusedLatLng={lat:latitude,lng:longitude};
        this.focusedAssetId=markerVal.deliveryCenterId || markerVal.pickupLocationId
      });
      this.startMarkerLocation = layerGroup([startMarkerLocation, orderMarker]);
      this.startMarkerLocation.addTo(this.map);
      this.allDeliveryMarkers.push(this.startMarkerLocation);
    } else {
      this.showInfo(`Location for Order ID ${orderLocationId} is Empty`);
    }
  }

  foucusedLatLng
  focusedAssetId:string

  circleWithOrderNumber(latLng, markerConfig) {


    const orderData = markerConfig.orderData;
    const namingConventionFilterPipe = new NamingConventionFilterPipe();


    const fillColor = this.orderStatusColorService.getStatusBackgroundColor(markerConfig.status);
    const textColor = this.orderStatusColorService.getStatusColor(markerConfig.status);

    const toolTipData = namingConventionFilterPipe.transform(orderData.status);

    const expectedDelData = this.dateFormatter.convertDateToSelectedTimezone(orderData.expectedDel.date, 'DD/MM/YY');
    const expectedDelTime = this.dateFormatter.getFormattedTimeSlot(orderData.expectedDel.slot);


    const circleOrderMarker = circleMarker(latLng,
      {
        color: textColor,
        fillColor: fillColor,
        fillOpacity: 0.8,
        radius: 15,
        weight: 3
      }
    );
    const icon = divIcon({
      html: `<div> <span class='leaflet-circle-marker-txt' style='color: ${textColor}'>${markerConfig.order} </span> </div>`,
      className: '',
      iconSize: [40, 40]
    });

    const orderMarker = marker(latLng, {
      icon: icon
    });

    const group = layerGroup([circleOrderMarker, orderMarker]);
    const orderSummary = `<div style='padding: 15px'>
      <div>
        <b>Order ${markerConfig.order}</b>
      </div>
      <div>
        <b>Order ID: </b>
        <span>${orderData.orderId}</span>
        </br>
        <b>Cust. Name: </b>
        <span>${orderData.customer.name}</span>
        </br>
        <b>Exp. Date: </b>
        <span> ${expectedDelData} - ${expectedDelTime}</span>
        </br>
        <b>Order Status: </b>
        <span style='border: 1px solid ${textColor}; color: ${textColor}; border-radius: 5px; padding: 3px; font-weight: bold'>${toolTipData}</span>
      </div>
    </div>`;
    orderMarker.bindPopup(orderSummary);
    return group;

  }

  panToSelectedPath(data) { 
    const routeData = data;
    const latLngs = routeData['latLngs'];
    const color = routeData['color'];
    const showThis = routeData['show'];
    const panToThis = routeData['isPanReq'];
    const name = routeData['name'];
    const distance = routeData['distance'];
    const isLatLngsValid = latLngs && latLngs.length > 0;
    if (isLatLngsValid) {
      if (showThis) {
        const routeArray = map(latLngs, function (data) {
          return new LatLng(data[0], data[1]);
        });
        if (name === 'Actual Path') {
          this.startingLocation.setLatLng(routeArray[0]).addTo(this.map);
          this.startingLocation.bindTooltip('<div><B>Start Point </B></div>');
          if (this.pageName === 'delivered') {
            this.endingLocation.setLatLng(routeArray[(routeArray.length) - 1]).addTo(this.map);
            this.endingLocation.bindTooltip('<div><B>End Point </B></div>');
          }
        }

        const route = polyline(routeArray, {'weight': 4, color, 'opacity': .9}).addTo(this.map)
          .bindPopup(
            `<b>${name}<b>
            </br>
            <b>${(distance ? distance : '')}<b>
        `, {closeButton: true});
        if (data?.name === 'Actual Path' && this.enableRoutePoints()) {
          this.removeActualPathMarkers();
          this.dataForGraph?.forEach(obj => {
            const marker = L.circleMarker({ lat: obj?.coordinates?.[0], lng: obj?.coordinates?.[1] }, {
              radius: this.getDynamicRadius(),
              color: '#005d81',
              fillOpacity: 1,
              weight: 2
            }).addTo(this.map);
            marker.bindPopup(getPopUpHeader('Sensor stats', '', this.dateFormator(obj.updated, 'lll',)) + showPopupData([{ label: 'Temp in °C : ', value: obj?.sensorData['temperature']?.toFixed(2) }])
              , { maxWidth: 1000, offset: [0, -8] })
            this.actualPathMarkers.push(marker);
          });
        }

        this.routes.push({id: name, route, show: true});
        setTimeout(() => {
          if (panToThis && route) {
            this.map.fitBounds(route.getLatLngs());
          }
        }, 500);
      }


    } else {
      this.showInfo(`Route for ${name} is Empty`);

      if (this.defaultMapDetails && this.defaultMapDetails.center) {
        this.map.setView(this.defaultMapDetails.center, this.defaultMapDetails.selectedZoom);
        this.map.setZoom(this.defaultMapDetails.selectedZoom);
      } else {
        this.setDefaultView();
      }
    }
  }

  panToActualPath() {
    const index = findIndex(this.routesDetails, function (o: any) {
      return o.name == 'Actual Path';
    });
    if (index > -1) {
      const routeData = this.routesDetails[index];
      if (this.routesDetails && this.routesDetails.length == 1) {
        routeData['show'] = true;
        this.panToSelectedPath(routeData);
      }
    }
  }

  setAgentTooltip(rowData) {
     const name = rowData?.assetDetails?.data?.name;
     const speed = parseFloat((rowData.speed || 0));
     const boxTemp = rowData?.sensorData?.temperature;
     const boxStatus = rowData?.sensorData?.coldChainBox;
     const humidity = rowData?.sensorData?.humidity;

     const popupData= []

     name           && popupData.push({label:'<img style="width: 15px; margin-right:3px" src="assets/asset-icons/licence-plate-25.png">',value:name})
     rowData?.speed && popupData.push({label:'<i style="width: 15px; margin-right:5px" class="fas fa-tachometer-alt"></i>',value:(Math.round(speed).toString() + ' km/hr')})
     boxTemp        && popupData.push({label:'<i style="width: 15px; margin-right:5px" class="fas fa-thermometer-full"></i>',value:(boxTemp + ' °C')})
     boxStatus      && popupData.push({label:'<i style="width: 15px; margin-right:5px" class="fas fa-box"></i>',value:boxStatus})
     humidity       && popupData.push({label:'<img style="width: 12px; margin-right:5px" src="assets/asset-icons/humidity.png">',value:(humidity+' %')})

    const subheader =this.dateFormatterToText(this.agentDetail?.locationLastUpdated)
    return getPopUpHeader('',this.agentDetail?.name || '', (subheader || '')) + showPopupData(popupData);

  }

  setDefaultView() {

        if (this.mapDetails) {
          if (this.mapDetails.zoom) {
            this.selectedZoom = this.mapDetails.zoom;
          } else {
            this.selectedZoom = 11;
          }
          if (this.mapDetails.centerLocation) {
            this.selectedLatitude = this.mapDetails.centerLocation.coordinates[0];
            this.selectedLongitude = this.mapDetails.centerLocation.coordinates[1];
          } else {
            this.selectedLatitude = 25.214017;
            this.selectedLongitude = 55.310220;
          }
          this.setMap(this.mapDetails);
          this.center = latLng(this.selectedLatitude, this.selectedLongitude);

          this.defaultMapDetails = {
            center: this.center,
            selectedZoom: this.selectedZoom,
          };
          if (this.defaultMapDetails && this.defaultMapDetails.center) {
            this.map.setView(this.defaultMapDetails.center, this.defaultMapDetails.selectedZoom);
            this.map.setZoom(this.defaultMapDetails.selectedZoom);
          }
        }

  }

  setRiderLastLocation(agentDetail) {
    if (agentDetail?.currentLocation) {
      const iconPath = (this.trackIcons?.active ? this.trackIcons.active : (this.trackIcons?.online ? this.trackIcons.online : '../assets/leaflet-icons/marker-icon-active.png'));
      this.agentMarkerLocation.setIcon(icon({
        iconSize: [35, 40],
        iconAnchor: [13, 38],
        iconUrl: iconPath,
        // shadowUrl: 'leaflet/marker-shadow.png',
        shadowAnchor: [13, 38]
      }));
      const lastCoordinates: any = [];
      lastCoordinates.push(agentDetail?.currentLocation?.latitude);
      lastCoordinates.push(agentDetail?.currentLocation?.longitude);
      this.agentMarkerLocation?.setLatLng(lastCoordinates).addTo(this.map);
      this.agentDetail=agentDetail
      this.agentMarkerLocation?.bindPopup(this.setAgentLastLocTooltup(agentDetail),{offset: [5, -20],});
      this.agentMarkerLocation?.on('mouseover',()=>{
        this.agentMarkerLocation.openPopup()
      })
      this.agentMarkerLocation?.on('mouseout',()=>{
        this.agentMarkerLocation.closePopup()
      })
    }
  }

  private agentDetail

  setRiderMarkerLocation(rowData) {
    if(rowData?.location?.latitude && rowData?.location?.longitude){
      const location = new LatLng(rowData?.location?.latitude, rowData?.location?.longitude);
      const lastUpdated = rowData.generatedTimeStamp;
      this.updateAgentPolyline(location, lastUpdated);
      this.agentMarkerLocation?.setLatLng(location).addTo(this.map);
      this.agentMarkerLocation?.bindPopup(this.setAgentTooltip(rowData));
      if (this._liveInfoTab.isFirstTimeAgentDataReceived) {
        this.map.panTo(location);
        setTimeout(() => {
          this.map.setZoomAround(location, 14);
        }, 500);
        this._liveInfoTab.isFirstTimeAgentDataReceived = false;
      }
    }
  }

  setAgentLastLocTooltup(agentDetail) {
    const subheader =this.dateFormatterToText(agentDetail.locationLastUpdated)
    return getPopUpHeader('Name : ',agentDetail.name || '', (subheader || '')) + showPopupData([]);
  }

  resetAgentRouteVariables() {

    this.agentMarkerLocation.remove();
    this.agentRoute.remove();
    this.agentRouteArray = [];
    this.newDataForAgentPolylineCount = 0;
  }

  buildGraph() {
    if (this.rowData) {
      this.getGraphData(this.rowData?.assetObj?.deliveryPerson?._id);
    }

  }

  camelCaseFormatter(name) {
    const text = name;
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  updateAgentPolyline(location: LatLng, lastDate) {
    const milliSeconds = new Date(lastDate).getTime();
    this.agentRouteArray.push({location: location, milliSeconds: milliSeconds});
    if (this.agentRoute && this.agentRoute['_map']) {
      this.agentRoute.addLatLng(location);
    } else {
      this.agentRoute = polyline([location], {'weight': 4, 'color': 'black', 'opacity': .9});
      this.map.addLayer(this.agentRoute);
    }
  }

  dateFormator(date, format?) {
    let dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
    if (format) {
      if (format === 'date') {
        dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
      } else if (format === 'time') {
        dateFormate = moment(date).format('h:mm:ss a');
      } else if (format === 'lll') {
        dateFormate = moment(date).format('LLL');
      }
    }
    return dateFormate;
  }

  plotSocketDataGraph(result) {
    if (result.generatedTimeStamp) {

      const update: EChartSeriesUpdate = {
        index: null,
        label: null,
        value: null,
        labelIndex: 0,
        values: []
      };

      update.label = (this.dateFormator(result.generatedTimeStamp, 'lll'));
      result.sensorData.temperature || result.sensorData.temperature == 0 ? update.values.push((result.sensorData.temperature).toFixed(2)) : update.values.push(null);
      result.sensorData.humidity || result.sensorData.humidity == 0 ? update.values.push((result.sensorData.humidity).toFixed(2)) : update.values.push(null);
      this.graphUpdateValue = cloneDeep(update);

    }
  }

  drawEChars(label, series) {
    // this._liveInfoTab.isGraphDisplayReq = true;
    this.boxStats.show = true;
    this.boxStats.showLoader = false;
    const type = 'line';
    if (this.moduleName) {
      if (this.moduleName === MMD_MODULE) {
        this.eChartGraphData.title = 'Sensor Stats';
      } else if (this.moduleName === LMD_MODULE) {
        this.eChartGraphData.title = 'Box Stats';
      }
    }

    this.eChartGraphData.type = type;
    const legend = keys(series);
    this.eChartGraphData.legend = map(legend, function (seriesName) {
      let seriesNameStr: string;
      switch (seriesName) {
        case 'temp':
          seriesNameStr = 'Temp in °C';
          break;
        case 'humidity':
          seriesNameStr = 'Humidity in %';
          break;
      }
      return seriesNameStr;
    });

    this.eChartGraphData.legendToDisable = 'Humidity in %';


    this.eChartGraphData.series = map(legend, function (seriesName) {
      let eChartSeries: EChartSeries;
      let chartColor: string;
      let seriesNameStr: string;
      switch (seriesName) {
        case 'temp':
          chartColor = '#ffc107';
          seriesNameStr = 'Temp in °C';
          break;
        case 'humidity':
          chartColor = '#28a745';
          seriesNameStr = 'Humidity in %';
          break;
      }
      if(chartColor){

      }
      eChartSeries = {
        data: series[seriesName],
        type: type,
        name: seriesNameStr,
        stack: false,
        smooth: false,
        label: {normal: {show: true, position: 'top'}},
        /* areaStyle: { normal: {} },
        itemStyle: {color: chartColor, opacity: 0.8} */
      };
      return eChartSeries;
    });


    this.eChartGraphData.xAxis = label;
    this.eChartGraphData = cloneDeep(this.eChartGraphData);
  }

  public graphUpdateValue: EChartSeriesUpdate = {
    index: null,
    label: null,
    value: null,
    labelIndex: 0,
    values: []
  };

  getGraphData(deviceId) {
    if (deviceId) {
      const data = orderBy(this.carGpsHistoricalData, ['updated'], ['asc']);
      this.dataForGraph = data;
      this.plotBoxStatsGraphForDelivered(data);
    } else {
      this.plotEmptyGraph();
    }
  }
  sendDataToPlotMarker(event){
    const inputDateTime =  event?.name;
    const foundObject = this.dataForGraph?.find((obj) => {
    const tempDate = this.dateFormator(obj.updated, 'lll');
      return tempDate.toString() === inputDateTime;
    });
    if (foundObject && foundObject?.coordinates?.length > 0) {
      let marker=  L.marker({lat:foundObject?.coordinates[0],lng:foundObject?.coordinates[1]})
      const icon = divIcon({
        html: `<div></div>`,
        className: '',
        iconSize: [40, 40]
      });
      marker.setIcon(icon);
      const tempDataArray = []
      const temperature = foundObject?.sensorData?.temperature;
      const humidity = foundObject?.sensorData?.humidity;
      temperature && tempDataArray.push({label:'Temp in °C : ',value:foundObject?.sensorData['temperature'].toFixed(2)})
      humidity && tempDataArray.push({label:'Humidity in % : ',value:foundObject?.sensorData['humidity'].toFixed(2)})
      marker.bindPopup(
        getPopUpHeader('Sensor stats','',this.dateFormator(foundObject?.updated, 'lll') ) + showPopupData([{label:'Temp in °C : ',value:foundObject?.sensorData['temperature']?.toFixed(2)}])
        , {maxWidth : 1000,offset: [0, -8]});
          setTimeout(()=>{
            marker?.openPopup()
          },500);

      marker.addTo(this.map);
      this.map?.panTo({ lat:foundObject?.coordinates[0],lng:foundObject?.coordinates[1]});
          setTimeout(()=> {
          this.map?.setZoom(17);
          }, 1000);
    } else {
      this.messageService.add({severity:'error', summary:'Error', detail:'Lat Lng not found'});
    }
  }

  plotGraph(result) {
    if (result && result.length > 0) {
      const groupByDate = groupBy(result, 'updated');
      const data = groupByDate;
      const yAxisKeys = keys(data);
      const yAxis = [];
      const xAxisByStatus = {
        temp: [],
        humidity: []
      };
      forEach(yAxisKeys, (dateString) => {
        const allStatusDetails = data[dateString];
        forEach(allStatusDetails, (countByStatus) => {
          if (countByStatus.sensorData) {
            xAxisByStatus.temp.push(countByStatus.sensorData.temperature || countByStatus.sensorData.temperature == 0 ? (countByStatus.sensorData.temperature).toFixed(2) : null);
            xAxisByStatus.humidity.push(countByStatus.sensorData.humidity || countByStatus.sensorData.humidity == 0 ? (countByStatus.sensorData.humidity).toFixed(2) : null);
            yAxis.push(this.dateFormator(dateString, 'lll'));
          }
        });
      });
      this.drawEChars(yAxis, xAxisByStatus);

    } else {
      const xAxisByStatus = {
        temp: [],
        humidity: []
      };
      const yAxis = [];
      this.drawEChars(yAxis, xAxisByStatus);
    }
  }

  plotEmptyGraph() {
    const xAxisByStatus = {
      temp: [],
      humidity: []
    };
    const yAxis = [];
    this.drawEChars(yAxis, xAxisByStatus);
  }

  plotBoxStatsGraphForDelivered(result) {
    if (result && result.length > 0) {
      this.liveDataFromSocketResultArray = cloneDeep(result);
      this.plotGraph(this.liveDataFromSocketResultArray);
    }
  }

  rightSideBarModalClicked() {
    const eChartGraphData = cloneDeep(this.eChartGraphData);
    const boxStats = cloneDeep(this.boxStats);
    const modalOptions = {
      eChartGraphData: eChartGraphData,
      boxStats: boxStats
    };
    this.sendChartExpansionData.emit({
      showModal: true,
      modalOptions: modalOptions
    });
  }

  onDialogHide(){
    this.markers=this.showGeofences ? [...(this.geomarkers || [])] : [];
    this.showEvents && this.plotEvents(this.eventsToPlotCopy);
    !this.showEvents && this.removeplottedEvents();
    this.routeLegendChanged.emit(this.legendList);
    this.setRiderLastLocation(this.agentDetails);
  }
  onDialogShow(){
    this.markers=this.showGeofences ? [...(this.geomarkers || [])] : [];
    this.showEvents && this.plotEvents(this.eventsToPlotCopy);
    !this.showEvents && this.removeplottedEvents();
    this.routeLegendChanged.emit(this.legendList);
    this.setRiderLastLocation(this.agentDetails);
  }

  legendClicked(l) {
    l.isActive = !l.isActive;
    this.startingLocation.remove();
    this.endingLocation.remove();
    this.routeLegendChanged.emit(this.legendList);

    /* forEach(this.filterMenuOptions, function (opt) {
      opt.active = false;
    });

    let newSelectedLegends = [];
    forEach(this.legendList, function (leg) {
      if(leg.isActive) {
        newSelectedLegends.push(leg);
      }
    });

    if (newSelectedLegends.length === 1) {
      if(newSelectedLegends[0].type==='actual'){
        this.filterMenuOptions[2]['active'] = true;
      }else if(newSelectedLegends[0].type==='expected') {
        this.filterMenuOptions[1]['active'] = true;
      }
    } else if (newSelectedLegends.length === 2) {
      this.filterMenuOptions[0]['active'] = true;
    } */

  }

  // prime ng panel resize event
  onPanelResize() {
    setTimeout(() => {
      this.map.invalidateSize(true);
    }, 100);
  }

  showInfo(msg) {
    this.messageService.add({severity: 'info', summary: 'Info', detail: msg});
  }

  dragEnd() {
    if (this.mapDetails) {
      this.setMap(this.mapDetails);
    }
    /* setTimeout(()=>{
        this.map.invalidateSize(true);
    }, 100); */
  }

  dateFormatterToText(date) {
    this.lang.updateMomentLanguage(moment);
    return moment(date).calendar();
  }

  plotEventsOnMap(e) {
    this.lang.updateMomentLanguage(moment);
    const generatedTimeStamp = moment(e?.generatedTimeStamp).calendar();
    const obj = {name: e?.name, status: e?.status};
    if (e.coordinates && e.coordinates.length > 0) {
      const newMarker = marker(e.coordinates,
        {
          icon: icon({
            iconSize: [15, 15],
            iconUrl: this.commonService.getIconsForEvents(obj)
          }),
          autoPan: true,
        });

      newMarker?.setLatLng(e?.coordinates)?.addTo(this?.map)?.bindPopup(
        getPopUpHeader('',e.description || '', (generatedTimeStamp || '')) + showPopupData([])
        , {maxWidth : 1000});

        newMarker?.on('mouseover',()=>{
          newMarker.openPopup()
        })
        newMarker?.on('mouseout',()=>{
          newMarker.closePopup()
        })
      this.eventsMarkers={...(this.eventsMarkers || {}),[e._id]:newMarker}

    }
  }

  resetLegendActiveVaues() {
    const isAgent = this.agentDetails ? true : false
    if(isAgent){
      forEach(this.legendList, (leg) => {
        if (leg.type === 'actual') {
          leg.isActive = true;
        } else {
          leg.isActive = false;
        }
      });
    } else {
       this.legendList.splice(0, 1);
       this.legendList[0].isActive = true;
    }

  }

  plotEvents(events) {
    forEach(events, (eventObj) => {
      this.bindEventIconForTableData(eventObj);
      this.plotEventsOnMap(eventObj);
    });
  }

  bindEventIconForTableData(data) {
    data['eventIcon'] = this.commonService.getIconsForEvents({name: data.name, status: data.status});
  }

  removeplottedEvents() {
    for(let id in (this.eventsMarkers || {})){
      this.eventsMarkers[id]?.remove()
    }
    this.eventsMarkers = {};
  }

  private removeLayer() {
    forEach(this.routes, r => {
      r?.route?.remove();
    });
    //this.route.remove();
    //this.routeArray = [];
    this.allDeliveryMarkers.forEach(markerObj => {
      markerObj.remove();
    });
    this.allDeliveryMarkers = [];
    this.allDeliveryLocMarkers = {};

    this.routes = [];
  }

  private plotPolyLine() {
    const isRoutesAvailable = this.routesDetails && this.routesDetails.length > 0;

    //console.log(`******* Is Route Available --> ${isRoutesAvailable} *********`)
    if (isRoutesAvailable) {
      //console.log(`******* No Of Routes --> ${this.routesDetails.length} *********`)
      //   this.buildPillsArray(this.routesDetails)
      forEach(this.routesDetails, routeData => {
        if (routeData && routeData.show) {
          this.panToSelectedPath(routeData);
        }
      });
    } else {
      if (this.defaultMapDetails && this.defaultMapDetails.center) {
        this.map?.setView(this.defaultMapDetails.center, this.defaultMapDetails.selectedZoom);
        this.map?.setZoom(this.defaultMapDetails.selectedZoom);
      } else {
        this.setDefaultView();
      }
    }

  }

  addEventListenerForPopup(){
     document.querySelector(".reject-popup-btn")?.addEventListener("click", () => {
         this.popupAction('reject')
       });
     document.querySelector(".accept-popup-btn")?.addEventListener("click", () => {
         this.popupAction('accept')
       });
   }

   popupAction(action){
    if(action==='accept'){
      this.sendLatLngValues.emit({ lat: this.foucusedLatLng?.lat, lng: this.foucusedLatLng?.lng, assetId: this.focusedAssetId });
    }
    else if(action==='reject'){this.plotIcons(true);}
   }

  removeActualPathMarkers() {
    this.actualPathMarkers.forEach(marker => this.map.removeLayer(marker));
    this.actualPathMarkers.length = 0;
  }

  togglePointsOnRoute(event) {
    if (!event) {
      this.removeActualPathMarkers();
    }
    this.plotPolyLine();
  }

  getDynamicRadius() {
    const currentZoom = this.map.getZoom();
    if (currentZoom >= 15) {
      return 4;
    } else if (currentZoom >= 10) {
      return 3;
    } else {
      return 1;
    }
  }


  ngOnDestroy() {
     this.userMapOptionsSub$?.unsubscribe();
     this.removeActualPathMarkers();
  }
}



export interface RouteLegend {
  'label': string,
  'color': string,
  'isActive': boolean,
  'type': string
}

export interface ILocationPayload{
  lat:number;
  lng:number;
  assetId:string;
  geoFenceRadius?:number;
  label?:String;
}
